
import { BusinessBranchModel } from 'tradingmate-modules/src/models/api/businesses'
import { Services } from 'tradingmate-modules/src/services'
import { Vue, Component } from 'vue-property-decorator'

@Component
export default class BusinessPicker extends Vue {
  private branches: BusinessBranchModel[] = []

  private errored = false

  mounted () {
    Services.API.Users.GetBusinessBranches().then((branches) => {
      this.branches = branches
    }).catch(() => {
      this.errored = true
    })
  }

  setBranch (branch: BusinessBranchModel) {
    this.$store.commit('identity/setBranch', branch)

    this.$emit('picked')

    if (this.$route.path !== '/') this.$router.push('/')
  }
}
