
import { Config } from 'tradingmate-modules/src/environment'
import { BusinessBranchModel } from 'tradingmate-modules/src/models/api/businesses'
import { Vue, Component } from 'vue-property-decorator'
import NavigationGroup from './NavigationGroup.vue'
import NavigationImpersonation from './NavigationImpersonation.vue'
import NavigationItem from './NavigationItem.vue'
import Utils from '../../../../../tradingmate-modules/src/Utils'

@Component({
  components: {
    NavigationImpersonation,
    NavigationGroup,
    NavigationItem
  }
})
export default class Navigation extends Vue {
  private branch: BusinessBranchModel = this.$store.state.identity.BusinessBranch;

  private basePath = Config.WebsiteUrls.Public

  get year () {
    return new Date().getFullYear()
  }

  setTheme (theme: string) {
    this.$store.commit('ui/setTheme', theme)
  }

  get websiteLink (): string {
    return `/my-websites/edit/${this.branch.BusinessBranchId}`
  }

  get dateEstablishedFormatted (): string {
    return Utils.isoToDisplayDate(this.branch.DateEstablished)
  }
}
